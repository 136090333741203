.login_wrapper {
  margin-top: 100px;
}

.admin_wrapper {
  margin-top: 30px;
}

.logout_btn {
  position: fixed;
  top: 12px;
  right: 10px;
}

@media (max-width: 991px) {
  .logout_btn {
    top: 60px;
  }
}